<template>
<div></div>
</template>

<script>
export default {
name: "Settings"
}
</script>

<style scoped>

</style>